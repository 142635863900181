import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemById,
  updateItem,
} from "../../../store/reducer/items/itemSlice";
import { getDdOptionForUI } from "../../../store/reducer/ddOption/ddOptionSlice";

function EditItem() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { options = [] } = useSelector((state) => state.ddOptions);

  useEffect(() => {
    dispatch(getItemById(id)).then((result) => {
      if (result.payload) {
        setItemData(result.payload);
        setLoading(false);
      }
    });
    dispatch(getDdOptionForUI({ ui_name: "Items", field_name: "Group" }));
  }, []);

  const validationSchema = Yup.object().shape({
    item_code: Yup.string().required("please enter item code"),
    item_description: Yup.string().required("please enter description"),
    uom: Yup.string().required("please enter unit of measurement"),
    group_id: Yup.string().required("please select group"),
    price_factor: Yup.number()
    .typeError("Please enter a valid price factor") // Ensures valid number
    .required("Please enter price factor")
    .positive("Price factor must be positive") // Optional: Ensures positive numbers
    .test('is-decimal', 'Price factor must be a decimal', (value) => (value + "").match(/^\d+(\.\d+)?$/)), // Validates float
    significance_factor: Yup.number()
    .typeError("Please enter a valid significance factor") // Ensures valid number
    .positive("significance factor must be positive") // Optional: Ensures positive numbers
  });

  const initialValues = {
    item_code: itemData?.item_code || "",
    item_description: itemData?.item_description || "",
    uom: itemData?.uom || "",
    group_id: itemData?.group_id || "",
    install_rate: itemData?.install_rate || "",
    install_accomodation_rate: itemData?.install_accomodation_rate || "",
    length_of_material: itemData?.length_of_material || "",
    price_factor: itemData?.price_factor || "",
    significance_factor: itemData?.significance_factor || "",
    sort_field: itemData?.sort_field || 0,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateItem({ data: formData, id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/setting/item");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  if (!loading) {
    return (
      <>
        <div className="flex justify-between mb-[30px]">
          <div className="flex gap-[16px] items-center">
            <Link to="/setting/dd-option">
              <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
                <img
                  className="w-[24px]"
                  src={process.env.PUBLIC_URL + "/icons/back.png"}
                  alt="back icon"
                />
              </div>
            </Link>
            <div className="flex flex-col">
              <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
                Item / Edit Item
              </span>
              <span className="font-Inter font-[700] leading-[30px] text-[24px]">
                Edit Item
              </span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px]">
                <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="item_code"
                    >
                      Item Code
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="item_code"
                      name="item_code"
                      onChange={(e) => {
                        setFieldValue("item_code", e.target.value);
                      }}
                    />
                    {errors.item_code && touched.item_code ? (
                      <div className="text-red-700">{errors.item_code}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="item_description"
                    >
                      Description
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="item_description"
                      name="item_description"
                      onChange={(e) => {
                        setFieldValue("item_description", e.target.value);
                      }}
                    />
                    {errors.item_description && touched.item_description ? (
                      <div className="text-red-700">
                        {errors.item_description}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="uom"
                    >
                      Unit Of Measurement
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="uom"
                        name="uom"
                        onChange={(e) => {
                          setFieldValue("uom", e.target.value);
                        }}
                      >
                        <option value={""}>Select unit</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Item" &&
                              option.field_name === "UOM"
                          )
                          .map((option) => (
                            <option value={option.id}>
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.uom && touched.uom ? (
                      <div className="text-red-700">{errors.uom}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="group_id"
                    >
                      Group
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="group_id"
                        name="group_id"
                        onChange={(e) => {
                          setFieldValue("group_id", e.target.value);
                        }}
                      >
                        <option value={""}>Select group</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Item" &&
                              option.field_name === "Group"
                          )
                          .map((option) => (
                            <option value={option.id}>
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.group_id && touched.group_id ? (
                      <div className="text-red-700">{errors.group_id}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="install_rate"
                    >
                      Install Rate
                    </label>
                    <Field
                      type="text"
                      name="install_rate"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("install_rate", e.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="install_accomodation_rate"
                    >
                      Install Accomodation Rate
                    </label>
                    <Field
                      name="install_accomodation_rate"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue(
                          "install_accomodation_rate",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="length_of_material"
                    >
                      Length Of Material
                    </label>
                    <Field
                      type="number"
                      name="length_of_material"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("length_of_material", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="price_factor"
                    >
                      Price Factor
                    </label>
                    <Field
                      type="number"
                      name="price_factor"
                      step="any" // Allows decimal numbers
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        const value = e.target.value || ""; // Ensure it's treated as a number
                        setFieldValue("price_factor", value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="significance_factor"
                    >
                      Significance Factor
                    </label>
                    <Field
                      type="number"
                      name="significance_factor"
                      step="any" // Allows decimal numbers
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        const value = parseFloat(e.target.value) || ""; // Ensure it's treated as a number
                        setFieldValue("significance_factor", value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="sort_field"
                    >
                      Sort By
                    </label>
                    <Field
                      type="number"
                      name="sort_field"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("sort_field", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default EditItem;
