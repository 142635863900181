import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../../store/reducer/ddOption/ddOptionSlice";
import { addExpensePeriod } from "../../../store/reducer/expensePeriod/expensePeriodSlice";

function AddExpensePeriod() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  useEffect(() => {
    dispatch(getDdOptionForUI());
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("please enter name"),
    start_date: Yup.date().required("please enter start date"),
    end_date: Yup.date().required("please enter end date"),
    status: Yup.number()
      .required("please select status"),
  });
  

  const getDefaultOption = (ui_name, field_name) => {
    const defaultOption = options.find((option) => {
      return (
        option.ui_name === ui_name &&
        option.field_name === field_name &&
        option.is_default === true
      );
    });
    return defaultOption ? defaultOption.id : "";
  };

  const initialValues = {
    name: "",
    start_date: "",
    end_date: "",
    status: getDefaultOption("ExpensePeriod", "Status")
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addExpensePeriod(formData)).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        resetForm();
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/setting/expense-period">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Expense Period / Add
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Add Expense Period
            </span>
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form>
            <div className="flex gap-[20px]">
              <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="name"
                    name="name"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                  {errors.name && touched.name ? (
                    <div className="text-red-700">{errors.name}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="start_date"
                  >
                    Start Date
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    id="start_date"
                    name="start_date"
                    onChange={(e) => {
                      setFieldValue("start_date", e.target.value);
                    }}
                  />
                  {errors.start_date && touched.start_date ? (
                    <div className="text-red-700">{errors.start_date}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="end_date"
                  >
                    End Date
                  </label>
                  <Field
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    id="end_date"
                    name="end_date"
                    onChange={(e) => {
                      setFieldValue("end_date", e.target.value);
                    }}
                  />
                  {errors.end_date && touched.end_date ? (
                    <div className="text-red-700">{errors.end_date}</div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <label
                    className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                    htmlFor="status"
                  >
                    Status
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="status"
                      name="status"
                      onChange={(e) => {
                        setFieldValue("status", e.target.value);
                      }}
                    >
                      <option value={""}>Select Status</option>
                      {options
                        .filter(
                          (option) =>
                            option.ui_name === "ExpensePeriod" &&
                            option.field_name === "Status"
                        )
                        .map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.field_code}
                          </option>
                        ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {errors.status && touched.status ? (
                    <div className="text-red-700">{errors.status}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
              <div>
                <button
                  type="submit"
                  name="submit"
                  className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                    isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddExpensePeriod;
