import React, { useEffect, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getExpensePeriodForUi } from "../../store/reducer/expensePeriod/expensePeriodSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import { getReimbursableExpensesSummaryByUser, getUserExpensesByExpensePeriod, getPersonalMileageByExpensePeriod } from "../../store/reducer/expenses/expensesSlice";
import downloadReport from "./utils/downloadReport"

function formatDateToDDMMYYYY(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year from the date
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

function ExpenseReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [expensePeriods, setExpensePeriods] = useState([]);
  const [users, setUsers] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  useEffect(() => {
    dispatch(getExpensePeriodForUi()).then(result => {
      if(result.payload) {
        setExpensePeriods(result.payload);
      }
    })
    const userSession = JSON.parse(localStorage.getItem("userSession"));
    if (userSession?.role == 1) { // Admin check
      setIsAdmin(true);
      dispatch(getUserForUI()).then((result) => {
        if (result.payload) {
          setUsers(
            result.payload
          );
        }
      });
    } else {
      setIsAdmin(false);
    }
  }, [])

  const validationSchema = Yup.object().shape({
    expense_period_id: Yup.number().required("Please select expense period"),
    expense_report_type: Yup.number().required("Please select report type"),
    output_type: Yup.number().required("Please select output type")
  });  

  const initialValues = {
    expense_period_id: "",
    user_id: "",
    expense_report_type: "",
    output_type: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      let result, reportTitle;
      switch (parseInt(values.expense_report_type)) {
        case 1:
          result = await dispatch(getReimbursableExpensesSummaryByUser(values)).unwrap();
          reportTitle = "Reimbursable Expenses"
          break;
        case 2:
          result = await dispatch(getUserExpensesByExpensePeriod(values)).unwrap();
          reportTitle = "All Expenses"
          break;
        case 3:
          result = await dispatch(getPersonalMileageByExpensePeriod(values)).unwrap();
          reportTitle = "Personal Mileage"
          break;
        default:
          toast.error("Invalid report type selected.");
          setSubmitting(false);
          return;
      }
      if (result) {
        if(result.length == 0) {
          toast.warning("No records found.")
        } else {
          await downloadReport(result, values.output_type, reportTitle); // Ensure download completes
          toast.success("Report generated successfully.");
          resetForm();
        }
      } else {
        toast.error("Failed to generate report.");
      }
    } catch (e) {
      console.error(e);
      toast.error("An error occurred while generating the report.");
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <Form>
              <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
                <h1 className="mb-[30px] text-[20px]">Expense Report</h1>
                <div className="grid grid-cols-4 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                    User
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                    Expense Period
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                    Report Type
                  </div>
                  <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                    Output Type
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-[20px] mb-[20px]">
                <div className="mb-2">
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_id"
                      name="user_id"
                      onChange={(e) => {
                        setFieldValue("user_id", e.target.value);
                      }}
                      disabled={!isAdmin}
                    >
                      {isAdmin ? (
                        <>
                          <option value="">Select User</option>
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.firstname + " " + user.lastname}
                            </option>
                          ))}
                        </>
                      ) : (
                        (() => {
                          const userSession = JSON.parse(
                            localStorage.getItem("userSession")
                          );
                          return (
                            <option value={userSession?.id} selected={true}>
                              {userSession?.firstname || ""}{" "}
                              {userSession?.lastname || ""}
                            </option>
                          );
                        })()
                      )}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="relative">
                    <Field as="select" 
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`expense_period_id`} 
                      name={`expense_period_id`}>
                      <option value="">Select an expense period</option>
                      {expensePeriods.map(period => (
                        <option key={period.id} value={period.id}>
                          {`${period.name ? period.name + ', ' : ''}${formatDateToDDMMYYYY(period.start_date)} / ${formatDateToDDMMYYYY(period.end_date)}`}
                        </option>
                      ))}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    <ErrorMessage name={`expense_period_id`} />
                  </div>
                </div>
                <div className="mb-2">
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="expense_report_type"
                      name="expense_report_type"
                      onChange={(e) => {
                        setFieldValue("expense_report_type", e.target.value);
                      }}
                    >
                      <option>Select report type</option>
                      <option value={1}>Reimbursable Expense</option>
                      <option value={2}>All Expenses</option>
                      <option value={3}>Personal Mileage</option>
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    
                    <ErrorMessage name={`expense_report_type`} />
                  </div>
                </div>
                <div className="mb-2">
                  <div className="relative">
                    <Field
                      as="select"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="output_type"
                      name="output_type"
                      onChange={(e) => {
                        setFieldValue("output_type", e.target.value);
                      }}
                    >
                      <option>Select output type</option>
                      <option value={1}>csv</option>
                      <option value={2}>pdf</option>
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                    
                    <ErrorMessage name={`output_type`} />
                  </div>
                </div>
                </div>
              </div>
                
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Generate"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ExpenseReport;
