import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listBusinessMileage = createAsyncThunk(
  "listBusinessMileage",
  async ({ expense_period_id, startDate, endDate, user }) => {
    let url = "api/admin/business-mileage";
    const queryParams = [];
    if (expense_period_id) {
      queryParams.push(`expense_period_id=${encodeURIComponent(expense_period_id)}`);
    }
    if (startDate) {
      queryParams.push(`startDate=${encodeURIComponent(startDate)}`);
    }
    if (endDate) {
      queryParams.push(`endDate=${encodeURIComponent(endDate)}`);
    }
    if (user) {
      queryParams.push(`user=${encodeURIComponent(user)}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
    const request = await api(url, "", "get");
    return request;
  }
);

export const getBusinessMileageById = createAsyncThunk(
  "getBusinessMileageById",
  async (id) => {
    const request = await api(`api/admin/business-mileage/${id}`, "", "get");
    return request;
  }
);

export const getBusinessMileageByOpportunity = createAsyncThunk(
  "getBusinessMileageByOpportunity",
  async (opportunity_id) => {
    const request = await api(`api/admin/business-mileage/opportunity/${opportunity_id}`, "", "get");
    return request;
  }
);

export const addBusinessMileage = createAsyncThunk(
  "addBusinessMileage",
  async (data) => {
    const request = await api("api/admin/business-mileage", data, "post");
    return request;
  }
);

export const editBusinessMileage = createAsyncThunk(
  "editBusinessMileage",
  async ({ data, id }) => {
    const request = await api(`api/admin/business-mileage/${id}`, data, "post");
    return request;
  }
);

export const deleteBusinessMileage = createAsyncThunk(
  "deleteBusinessMileage",
  async (id) => {
    const request = await api(`api/admin/business-mileage/delete/${id}`, "", "get");
    return request;
  }
);


const businessMileageSlice = createSlice({
  name: "businessMileage",
  initialState: {
    loading: false,
    journeys: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listBusinessMileage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listBusinessMileage.fulfilled, (state, action) => {
        state.journeys = action?.payload?.rows;
        state.loading = false;
        state.error = null;
      })
      .addCase(listBusinessMileage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addBusinessMileage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBusinessMileage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addBusinessMileage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteBusinessMileage.fulfilled, (state, action) => {
        state.loading = false;
        state.journeys = state.journeys.filter(journey => {
          return journey.id != action.payload.journey?.id
        })
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteBusinessMileage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editBusinessMileage.fulfilled, (state, action) => {
        state.loading = false;
        state.journeys = [];
        state.error = null;
      })
      .addCase(editBusinessMileage.rejected, (state, action) => {
        state.loading = false;
        state.journeys = [];
        state.error = action.error.message;
      })
  },
});
export default businessMileageSlice.reducer;
